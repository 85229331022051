import Head from 'next/head'
import React, { useEffect } from 'react'

import { CallToAction } from '../components/newhome/CallToAction'
import { Faqs } from '../components/newhome/Faqs'
import { Footer } from '../components/newhome/Footer'
import { Header } from '../components/newhome/Header'
import { Hero } from '../components/newhome/Hero'
import { PricingNew } from '../components/newhome/Pricing'
import { PrimaryFeatures } from '../components/newhome/PrimaryFeatures'
import { SecondaryFeatures } from '../components/newhome/SecondaryFeatures'
import { Testimonials } from '../components/newhome/Testimonials'
import { ThirdFeatures } from '../components/newhome/ThirdFeature'
import { SERVICE_CART_PNG } from '../constants/app.constants'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import LoadingAnimation from '../components/loading/loadingAnimation.component'

export default function Home() {
  const router = useRouter()
  const {user} = useSelector((state:any) => state.user)

  useEffect(() => {
    if (user?._id) {
      router.push('/contractor/home')
    } else {
      router.push('/sign-in')
    }
  }, [user])

  return (
    <>
    <LoadingAnimation />
      {/* <Head>
        <link rel="shortcut icon" href='/fav_3.png' />
        <title> Service Cart </title>
        <meta
          name="description"
          content="The ultimate sales tool for home cleaning companies"
        />
      </Head>
      <Header />
      <main>
        <Hero />
        <PrimaryFeatures />
        <SecondaryFeatures /> */}
        {/* <ThirdFeatures/> */}
        {/* <CallToAction /> */}
        {/* <Testimonials /> */}
        {/* <br />
        <br />
        <br />
        <PricingNew />
        <Faqs />
      </main>
      <Footer /> */}
    </>
  )
}